import request from "./request"; //引入封装好的axios

const api ={
    // 把所有接口都在这里定义出来
    getConfig:'api/index/getConfig', //公共配置
    webone:'api/index/getWebpage1List',//网站接口
    webtwo:'api/index/getWebpage2List'
}

//公共配置
export function getConfig(){
    return request({
        url:api.getConfig,
        method:"get",
        params:{type:1},//版本1 = 1 ； 版本2 = 2
    })
}
export function webone(e){
    return request({
        url:api.webone,
        method:"get",
        params:e,
    })
}
export function webtwo(e){
    return request({
        url:api.webtwo,
        method:"get",
        params:e,
    })
}






// export function housegeng(parameter){
//     return request({
//         url:api.housegeng,
//         method:"POST",
//         data:parameter
//     })
// }
