<template>
  <div>
    <!-- 状态1 -->
    <div v-if="window == 0"></div>

    <!-- 状态2 -->
    <div v-if="window == 1" class="winone">
      <div class="top">
        <img class="picimg" :src="webimg.value" alt="">
        <div class="right">
          <div class="name">{{ webname.value }}</div>
          <div class="txt">{{ webtxt.value }}</div>
          <div class="icon">
            <div @click="dian(dianbao.value)">
              <img src="../logo/dian.png" alt="">
            </div>
            <div @click="dian(youtube.value)">
              <img src="../logo/you.png" alt="">
            </div>
            <div @click="dian(tuite.value)">
              <img src="../logo/tui.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="shu">
        <div v-for="item in arrone" :key="item.id" class="item" @click="dian(item.url)">
          <div class="left">
            <img :src="item.image" alt="">
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="right">
            <div class="dian"></div>
            <div class="dian"></div>
            <div class="dian"></div>
          </div>
        </div>
      </div>
      <div class="heng">
        <div class="wai">
          <div class="nei">
            <div class="item" v-for="item in arrtwo" :key="'two' + item.id" @click="dian(item.url)">
              <img :src="item.image" alt="">
              <div class="txt">{{ item.name }}</div>
            </div>
            <div v-if="arrtwo.length > 3" style="flex: 0 0 3px;"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- 状态3 -->
    <div v-if="window == 2" class="wintwo">

      <div class="top">
        <img class="picimg" :src="webimg.value" alt="">
        <div class="right">
          <div class="name">{{ webname.value }}</div>
          <div class="txt">{{ webtxt.value }}</div>
          <div class="icon">
            <div @click="dian(dianbao.value)">
              <img src="../logo/dian.png" alt="">
            </div>
            <div @click="dian(youtube.value)">
              <img src="../logo/you.png" alt="">
            </div>
            <div @click="dian(tuite.value)">
              <img src="../logo/tui.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="heng">
        <div class="wai">
          <div class="nei">
            <div class="item" v-for="item in arrtwo" :key="'two' + item.id" @click="dian(item.url)">
              <img :src="item.image" alt="">
              <div class="txt">{{ item.name }}</div>
            </div>

            <div v-if="arrtwo.length > 3" style="flex: 0 0 3px;"></div>
          </div>
        </div>
      </div>
      <div class="shu">
          <div v-for="item in arrone" :key="item.id" class="item" @click="dian(item.url)">
            <div class="left">
              <img :src="item.image" alt="">
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="right">
              <div class="dian"></div>
              <div class="dian"></div>
              <div class="dian"></div>
            </div>
          </div>

        </div>


    </div>

    <!-- 遮罩层 -->
    <div class="zhe" v-if="showzhe">
      <img src="../logo/jiazai.png" alt="" />
    </div>

    <!-- 提示框 -->
    <div class="tishi" :class="[showtishi ? 'tishis' : '']" v-html="tishi"></div>
  </div>
</template>
<script>
// import Notice from "../components/Notice";
// import ClipboardJS from "clipboard";

/* 
  getConfig   公共配置
  ones  //网站数据
  two //网站数据
*/
import {
  getConfig, webone, webtwo
} from "../units/api.js";
export default {
  data() {
    return {
      showtishi: false, //提示框判断
      tishi: "", //提示框信息
      showzhe: false, //遮罩层

      window: 0,//布局显示

      webname: "", //网站名称
      webimg: '',//网站图标
      webtxt: '',//网站介绍

      dianbao: "", //电报
      youtube: "", //youtube
      tuite: "", //推特

      arrone: [],//第一组
      arrtwo: [],//第二组
    };
  },

  components: {
    // Notice
  },

  created() {
    this.fungetConfig()
    this.funwebone()
    this.funwebtwo()

  },
  methods: {
    //公共配置
    fungetConfig() {
      this.showzhe = true;
      getConfig().then((res) => {
        console.log(res);
        this.showzhe = false;
        if (res == undefined) {
          this.funti("网络错误,请刷新重试")
        }
        if (res.code == 1) {
          res.data.forEach((item) => {
            if (item.title == '网站名称') {
              this.webname = item
            }
            if (item.title == '网站头像') {
              this.webimg = item
            }
            if (item.title == '网站介绍') {
              this.webtxt = item
            }
            if (item.title == '飞机') {
              this.dianbao = item
            }
            if (item.title == 'YOUTUBI') {
              this.youtube = item
            }
            if (item.title == '推特') {
              this.tuite = item
            }
            if (item.title == '布局') {
              this.window = item.value
            }
          })
        }
      }).catch(() => {
        this.funti("网络错误,请刷新重试")
        this.showzhe = false;
      })
    },
    funwebone() {
      webone().then((res) => {
        console.log(1, res);
        if (res.code == 1) {
          this.arrone = res.data.data
        }
      })
    },
    funwebtwo() {
      webtwo().then((res) => {
        console.log(2, res);
        if (res.code == 1) {
          this.arrtwo = res.data.data
        }
        // for(let i =0;i<4;i++){
        //   this.arrtwo.push(this.arrtwo[0])
        // }
      })
    },


    // 点击
    dian(e) {
      window.location.href = e
    },

    // 开启提示框
    funti(e) {
      console.log(1);

      this.tishi = e;
      this.showtishi = true;

      let a = setInterval(() => {
        this.showtishi = false;
        this.tishi = "";
        clearInterval(a);
      }, 2000);
    },

    //客服跳转
    tiao(e) {
      window.location.href = e
    },
  },
};
</script>
<style scoped lang="scss">
.router-link {
  text-decoration: none !important;
}

@media screen and (min-width: 1200px) {

  .winone {
    width: 752px;
    margin: 32px auto;
    background-color: #ffffff;

    .top {
      text-align: center;

      .picimg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .name {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        margin-top: 5px;
      }

      .txt {
        margin: 5px auto 0;
        font-size: 14px;
        width: 480px;
        text-align: center;
        overflow-wrap: break-word;
      }

      .icon {
        margin: 10px auto 0;
        width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          cursor: pointer;
        }

        img {
          width: 32px;
          height: auto;
        }
      }
    }

    .shu {
      margin-top: 15px;

      .item {
        width: 752px;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 24px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
        color: #333333;
        position: relative;
        cursor: pointer;
        margin-bottom: 15px;

        .left {
          display: flex;
          align-items: center;

          img {
            width: 56px;
            border-radius: 50%;
          }

          .name {
            font-size: 16px;
            margin-left: 20px;
            width: 56 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .right {
          height: 15px;
          position: absolute;
          right: 24px;
          top: 33px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .dian {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #d8d8d8;

          }
        }
      }
    }

    .heng {

      width: 768px;
      .wai {
        overflow: auto;
        margin-left: -10px;

        .nei {
          padding: 10px;

          display: flex;
          height: 200px;

          // scrollbar-width: none;
          .item {
            background-color: #ffffff;
            flex: 0 0 240px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            margin-right: 16px;
            height: 180px;
            cursor: pointer;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              height: 140px;
            }

            .txt {
              height: 40px;
              padding: 0 10px;
              box-sizing: border-box;
              font-size: 14px;
              color: #333333;
              line-height: 40px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

        }
      }
    }
  }

  .wintwo {
    width: 752px;
    margin: 32px auto;
    background-color: #ffffff;
    // padding: 30px;
    // padding: 50px 120px;

    // border-radius: 20px;
    // box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    .top {
      text-align: center;

      .picimg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .name {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        margin-top: 5px;
      }

      .txt {
        margin: 5px auto 0;
        font-size: 14px;
        width: 480px;
        text-align: center;
        overflow-wrap: break-word;
      }

      .icon {
        margin: 10px auto 0;
        width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          cursor: pointer;
        }

        img {
          width: 32px;
          height: auto;
        }
      }
    }

    .shu {
      margin-top: 15px;

      .item {
        width: 752px;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 24px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
        color: #333333;
        position: relative;
        cursor: pointer;
        margin-bottom: 15px;

        .left {
          display: flex;
          align-items: center;

          img {
            width: 56px;
            border-radius: 50%;
          }

          .name {
            font-size: 16px;
            margin-left: 20px;
            width: 56 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .right {
          height: 15px;
          position: absolute;
          right: 24px;
          top: 33px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .dian {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #d8d8d8;

          }
        }
      }
    }

    .heng {

      width: 768px;
      margin-top: 10px;

      .wai {
        overflow: auto;
        margin-left: -10px;

        .nei {
          padding: 10px;

          display: flex;
          height: 190px;

          // scrollbar-width: none;
          .item {
            background-color: #ffffff;
            flex: 0 0 240px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            margin-right: 16px;
            height: 180px;
            cursor: pointer;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              height: 140px;
            }

            .txt {
              height: 40px;
              padding: 0 10px;
              box-sizing: border-box;
              font-size: 14px;
              color: #333333;
              line-height: 40px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

        }
      }
    }
  }






  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 20px;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 40px;
    // min-height: 54px;
    // line-height: 54px;
    border-radius: 3rem;
    transition: all 0.2s ease-in-out;
    text-align: center;
  }

  .tishis {
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100px;
      top: 50vh;
      left: 50vw;
      margin-top: -50px;
      margin-left: -50px;
      transform-origin: 50px 50px;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .winone,
  .wintwo {
    padding: 30rem 12rem;
    box-sizing: border-box;
  }








  .winone {

    .top {
      display: flex;
      padding: 0 8rem;
      .picimg {
        width: 60rem;
        height: 60rem;
        border-radius: 50%;
      }

      .right {
        margin-left: 8rem;

        .name {
          font-size: 16rem;
          font-weight: bold;
          color: #000000;
        }

        .txt {
          font-size: 11rem;
          margin-top: 4rem;
          color: #666666;
        }

        .icon {
          margin-top: 8rem;
          display: flex;
          align-items: center;

          img {
            width: 25rem;
            margin-right: 15rem;

          }
        }
      }
    }


    .shu {
      margin-top: 25rem;

      .item {
        width: 100%;
        border-radius: 20rem;
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
        position: relative;

        .left {
          display: flex;
          align-items: center;

          img {
            width: 40rem;
            height: 40rem;
            border-radius: 50%;
            padding: 6rem;
          }

          .name {
            width: 73%;
            font-size: 13rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .right {
          position: absolute;
          top: 20rem;
          right: 12rem;

          .dian {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: #B9AAB6;
            margin-top: 2rem;
          }
        }
      }
    }

    .heng {
      margin-top: 15rem;

      .wai {
        width: 363rem;

        .nei {
          display: flex;
          overflow-x: auto;
          padding: 5rem 5rem 10rem 5rem;
          margin-left: -5rem;

          .item {
            flex: 0 0 109rem;
            height: 92rem;
            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin-right: 12rem;

            img {
              width: 109rem;
              height: 64rem;
            }

            .txt {
              height: 28rem;
              color: #333333;
              font-size: 12rem;
              line-height: 28rem;
              padding: 0 6rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

    }

  }

  .wintwo {


    .top {
      display: flex;
      padding: 0 8rem;

      .picimg {
        width: 60rem;
        height: 60rem;
        border-radius: 50%;
      }

      .right {
        margin-left: 8rem;

        .name {
          font-size: 16rem;
          font-weight: bold;
          color: #000000;
        }

        .txt {
          font-size: 11rem;
          margin-top: 4rem;
          color: #666666;
        }

        .icon {
          margin-top: 8rem;
          display: flex;
          align-items: center;

          img {
            width: 25rem;
            margin-right: 15rem;

          }
        }
      }
    }


    .shu {
      margin-top: 10rem;

      .item {
        width: 100%;
        border-radius: 20rem;
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
        position: relative;

        .left {
          display: flex;
          align-items: center;

          img {
            width: 40rem;
            height: 40rem;
            border-radius: 50%;
            padding: 6rem;
          }

          .name {
            width: 73%;
            font-size: 13rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .right {
          position: absolute;
          top: 20rem;
          right: 12rem;

          .dian {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: #B9AAB6;
            margin-top: 2rem;
          }
        }
      }
    }

    .heng {
      margin-top: 15rem;

      .wai {
        width: 363rem;

        .nei {
          display: flex;
          overflow-x: auto;
          padding: 5rem 5rem 10rem 5rem;
          margin-left: -5rem;

          .item {
            flex: 0 0 109rem;
            height: 92rem;
            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin-right: 12rem;

            img {
              width: 109rem;
              height: 64rem;
            }

            .txt {
              height: 28rem;
              color: #333333;
              font-size: 12rem;
              line-height: 28rem;
              padding: 0 6rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

    }
  }




  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 18rem;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15rem;
    border-radius: 5rem;
    transition: all 0.3s ease-in-out;
    width: 200rem;
    text-align: center;
  }

  .tishis {
    // top: 160rem;
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100rem;
      top: 50vh;
      left: 50vw;
      margin-top: -50rem;
      margin-left: -50rem;
      transform-origin: 50rem 50rem;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>